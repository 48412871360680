export const baseURLImage: string = 'https://upbetx.s3.ap-southeast-1.amazonaws.com/images/providers-v2/'
// export const baseURLImagePromotion: string = 'https://opacity.mo.cloudinary.net/small/promotion-v2/'
export const baseURLImageAvatar: string = 'https://opacity.mo.cloudinary.net/small/configs/avatar/'


//aws
export const baseURLImagePromotion = 'https://upbetx.s3.ap-southeast-1.amazonaws.com/images/promotion-v2/'


//cloudinary
// export const baseURLImageAvatar: string = 'https://opacity.mo.cloudinary.net/small'
